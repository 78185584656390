<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title_header">
        <div class="list_title">我的订阅</div>
        <div class="clear" @click="clearAll">清空</div>
      </div>
      <div
        :class="['list_box', subscribeList.length < 6 ? 'list_boxActive' : '']"
        v-loading="loading"
        element-loading-text="正在加载数据"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0,.1)"
      >
        <div class="list_child" @click="getDetails(item)" v-for="(item, index) in subscribeList" :key="index">
          <svg-icon v-if="item.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
          <img :src="item.imgPath" alt="" />
          <div class="styleClass">{{ item.styleNumber }}</div>
          <div class="titleClass">{{ item.productName }}</div>
          <div class="timerClass">{{ item.updateTime ? item.updateTime : item.createTime }}</div>
          <div v-if="item.stockQuantity > 0" class="inStock shopTips">已到货</div>
          <div v-else class="onShop shopTips">暂时无货</div>
        </div>
        <div v-if="subscribeList.length <= 0" style="text-align:center;margin:200px 0;width: 100%;">
          <img src="@/Img/noData.png" alt />
        </div>
      </div>
      <div class="boe_fenye">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="obj.current"
          :page-sizes="[12, 24, 36, 48, 60, 120]"
          :page-size="obj.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
import { subscribeList, allCancelSubscribe, readStatus } from '@/api/detailsApi';
import ls from '@/utils/localStorage';
export default {
  name: 'Subscribe',
  data() {
    return {
      userData: {},
      obj: {
        current: 1,
        size: 12,
        buyerId: ls.get('userData').userCode,
        frontPort: this.$route.query.frontPort,
      },
      loading: true,
      total: 0,
      subscribeList: [],
      styleNumberList: [],
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 跳转商品详情页08JD019
    getDetails(data) {
      if (data.stockQuantity > 0) {
        const ids = [data.id];
        if (this.$route.query.frontPort == 2) {
          this.readStatus(ids, data);
        }
      }
    },
    // 分页组件切换页数
    handleSizeChange(val) {
      this.obj.size = val;
      this.getList();
    },
    // 分页组件切换
    handleCurrentChange(val) {
      this.obj.current = val;
      this.getList();
    },
    // 清空所有订阅
    clearAll() {
      if (this.styleNumberList.length === 0) {
        this.$message({
          message: '没有可清空的订阅',
          type: 'warning',
          duration: 1500,
        });
      } else {
        this.$confirm('该操作会清空订阅', '提示', {
          confirmButtonText: '确定清空',
          cancelButtonText: '取消',
          type: 'success',
        })
          .then(() => {
            let obj = {
              styleNumberList: this.styleNumberList,
            };
            allCancelSubscribe(obj).then((res) => {
              this.$message({
                message: '清空成功',
                type: 'success',
                duration: 1000,
              });
              this.getList();
            });
          })
          .catch(() => {});
      }
    },
    getList() {
      this.loading = true;
      // const { current, size, buyerId, frontPort } = this.obj;
      // const formData = new FormData();
      // formData.append('current', current);
      // formData.append('size', size);
      // formData.append('buyerId', buyerId);
      // formData.append('frontPort', frontPort);
      subscribeList(this.obj).then((res) => {
        this.subscribeList = [];
        this.styleNumberList = [];
        this.total = res.data.total;
        this.loading = false;
        res.data?.records.forEach((element) => {
          this.subscribeList.push(element);
          this.styleNumberList.push(element.styleNumber);
        });
      });
    },
    readStatus(ids, data) {
      readStatus(ids).then((res) => {
        if (data.classify == '2') {
          this.$router.push({
            path: '/order',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              subId: data.id,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '1') {
          this.$router.push({
            path: '/plaingold',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              subId: data.id,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '3') {
          this.$router.push({
            path: '/watch',
            query: {
              id: data.productId,
              generaName: data.title,
              productName: '手表现货',
              serialNumber: data.serialNumber,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              subId: data.id,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '4') {
          this.$router.push({
            path: '/goldsetting',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              subId: data.id,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 880px;
}
.list_title_header {
  display: flex;
  justify-content: space-between;
  margin: 28px 0;
  .clear {
    font-size: 20px;
    cursor: pointer;
    color: red;
  }
}
.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.boe_fenye {
  text-align: center;
  margin-top: 20px;
}
.list_box {
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  .pre-sale-label {
    position: absolute;
    width: 88px;
    height: 28px;
    right: 0;
    top: 0;
  }
}
.list_boxActive {
  display: flex;
  flex-wrap: wrap;
  .list_child {
    margin-left: 8px;
  }
}
.list_child:hover {
  .collection_delete {
    display: block;
    cursor: pointer;
  }
}
.list_child {
  width: 240px;
  border: 1px solid #ec9368;
  box-sizing: border-box;
  position: relative;
  .collection_delete {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  img {
    width: 100%;
    height: 238px;
  }
  .styleClass {
    font-size: 16px;
    color: #ec9368;
    margin: 5px 0 5px 8px;
  }
  .titleClass {
    font-size: 16px;
    color: #000;
    margin-left: 8px;
    margin-bottom: 5px;
  }
  .timerClass {
    font-size: 14px;
    color: #999;
    margin-left: 8px;
    margin-bottom: 5px;
  }
  .collectionStock {
    width: 103px;
    height: 103px;
  }
  .shopTips {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .inStock {
    background: #ec9368;
  }
  .onShop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
